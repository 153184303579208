import React from "react";
import {Col, Card, Media} from 'react-bootstrap';
import styled from 'styled-components';
import CTAButton from '../utils/CTAButton'
import Loader from '../../images/LoaderOrange.svg';

const CouponDiv = styled(Col)`
  margin-bottom: 1.5rem;
  .card {
    height: 100%;
    padding: .5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .media img {
    margin-right: 1rem;
    width: 100px;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: .25rem;
  }
  h5 {
    color: ${props => props.theme.colors.base};
    font-size: 1.125rem;
    margin-bottom: 0;
  }
  .media {
    height: 100%;
  }
  
  .media-body {
    height: 100%;
    display: flex;
    p {
      margin-bottom: .25rem;
      &:last-child {
        margin-bottom: .75rem;
      }
    }
    small {
      font-size: .875rem;
    }
    strong {
       font-weight: 600;
       color: ${props => props.theme.colors.base};
     }
  }
  @media (max-width: 475px) {
    button {
      height: auto !important;
    }
  }
  
  button {
    min-width: 10rem;
  }
  
  &.saved button {
    cursor: default !important;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.baseLight};
    border-color: ${props => props.theme.colors.baseLight};
    &:hover {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.baseLight};
      border-color: ${props => props.theme.colors.baseLight};
    }
  }
  &.saving button {
    cursor: default !important;
    img {
      width: 1.25rem;
      height: auto;
      margin-right: 0;
    }
    &:hover {
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`

const Coupon = ({ coupon, buttonFunction }) => {
  let saveButton = (coupon) => {
    if (coupon.state == "saved") {
      return <CTAButton size="small" variant="solid-white" data-coupon-id={coupon.id}>{[String.fromCharCode(10003), " Saved"]}</CTAButton>
    } else if (coupon.state == "saving") {
      return <CTAButton size="small" variant="solid-white" data-coupon-id={coupon.id}><img src={Loader} alt="loading" /></CTAButton>
    } else {
      return <CTAButton size="small" variant="solid-white" data-coupon-id={coupon.id} onClick={buttonFunction}>Save Coupon</CTAButton>
    }
  }
  return (
    <CouponDiv className={`${coupon.state}`}>
      <Card>
      <Media>
        <img
          src={`${coupon.ImageUrl}`}
          alt={`${coupon.OfferShortDesc}`}
        />
        <Media.Body className={"d-flex flex-column justify-content-between"}>
          <div>
            <h4>{`${coupon.Brand}`}</h4>
            <h5>{`${coupon.OfferSummaryTop}`}</h5>
            <p>
              {`${coupon.OfferSummaryDetail}`}
            </p>
            <p><small>{`${coupon.ExpirationDateString}`} &nbsp;&nbsp;<strong>{`${coupon.DaysLeftString}`}</strong></small></p>
          </div>
          <div className={"save-button"}>{saveButton(coupon)}</div>
        </Media.Body>
      </Media>	
      </Card>
    </CouponDiv>
  )
};

export default Coupon;