import React, {Component} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, Modal, Card, Media, Nav, Tab, Form, Alert} from 'react-bootstrap';
import Loader from '../../images/Loader.svg';
import CTAButton from '../utils/CTAButton'
import Checkbox from '../utils/Checkbox'
import ScrollToTop from '../utils/ScrollToTop'
import PageTitle from '../PageTitle'
import { isLoggedIn, getUser, getUserToken, isBrowser, handleLogout } from "../User/User"
import LoginForm from "../UserForms/LoginForm"
import SignupForm from "../UserForms/SignupForm"
import Coupon from "./Coupon"

const ExternalWrapper = styled.div`
  padding: 5rem 0 5rem 0;
  @media (min-width: 768px) {
    margin-bottom: 120px;
  }
`

const CouponsHeader = styled(Container)`
  margin-bottom: 1rem;
  h1 {
    margin-bottom: 2rem;
  }
  #userButtons {
    margin-top: -2.5rem;
    float: right;
    
    @media (max-width: 475px) {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      button {
        margin: auto;
      }
    }
    
    a, button + a, button {
      margin-left: 1rem;
    }
  }
`

const CouponsNav = styled(Nav)`
  float: right;
  margin-bottom: 1rem;
  @media (max-width: 475px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .nav-link {
    border: 2px solid ${props => props.theme.colors.base};
    border-radius: 0;
    font-family: 'montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.base};
    padding: 5px 1rem;
    &.active {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.base};
    }
  }
`

const CouponButtons = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 475px) {
    width: 100%;
    text-align: center;
  }
`

const CouponsLoader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
  img {
    width: 75px;
    height: auto;
    margin-bottom: 2rem;
  }
`

const CouponsFilter = styled(Col)`
  margin-bottom: 2rem;
  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${props => props.theme.colors.base};
  }
  .options {
    max-height: 9rem;
    overflow-y: scroll;
    padding-left: 1px;
  }
  a {
    display: block;
    margin-top: .5rem;
  }
  
  @media (min-width: 992px) {
    .options {
      max-height: 17rem;
    }
  }
    
`

const LoginModalBody = styled(Modal.Body)`
  background-color: ${props => props.theme.colors.base};
  box-shadow: 2px 2px 10px ${props => props.theme.colors.secondary};
  
  color: ${props => props.theme.colors.white};
  a {
    color: ${props => props.theme.colors.white};
  }
  button {
    color: ${props => props.theme.colors.base};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.secondary};
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.white};
    }
  }
`

const LoginModalNav = styled(Nav)`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  
  .nav-link {
    border-radius: 0;
    font-family: 'montserrat', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
    &.active {
      color: ${props => props.theme.colors.base};
      background-color: ${props => props.theme.colors.white};
      padding: .5rem 3rem;
    }
  }
`


export default class CouponsPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      coupons: [],
      categories: [],
      brands: [],
      show: false,
      user: (isLoggedIn() ? getUser() : null),
      token: (isLoggedIn() ? getUserToken() : ''),
      activeCouponsTab: "all",
      activeModalTab: "signin",
      couponSort: "id",
    }
  }
  
  categorySelection = (e) => {
    e.preventDefault;
    
    let categoryName = e.target.getAttribute('name');
    let categories = this.state.categories;
    
    if (categoryName == "ALL") {
      categories.forEach(function(category, index) {
        category.selected = false;
      });
    } else {
      let currentCategory = categories.find(category => category.name == categoryName);
      (currentCategory.selected) ? currentCategory.selected = false : currentCategory.selected = true;
    }
    this.setState({
      categories: categories
    })
    
    this.filterCoupons();
  }
  
  brandSelection = (e) => {
    
    let brandName = e.target.getAttribute('name');
    let brands = this.state.brands;
    
    if (brandName == "ALL") {
      brands.forEach(function(brand, index) {
        brand.selected = false;
      });
    } else {
      let currentBrand = brands.find(brand => brand.name == brandName);
      (currentBrand.selected) ? currentBrand.selected = false : currentBrand.selected = true;
    }
    
    this.setState({
      brands: brands
    })
    
    this.filterCoupons();
  }
  
  filterCoupons = () => {
    let selectedCategories = [];
    let selectedBrands = [];
    
    this.state.categories.filter(category => category.selected == true).forEach(function(category,index){
     selectedCategories.push(category.name)
    });
   
    this.state.brands.filter(brand => brand.selected == true).forEach(function(brand,index){
     selectedBrands.push(brand.name)
    });

    let coupons = this.state.coupons;
    
    coupons.forEach(function(coupon, index) {
      if ((selectedCategories.length > 0) && (selectedCategories.indexOf(coupon.CategoryName) == -1)) {
        coupons[index]["show"] = false;
      } else if ((selectedBrands.length > 0) && (selectedBrands.indexOf(coupon.Brand) == -1)) {
        coupons[index]["show"] = false;
      } else {
        coupons[index]["show"] = true;
      }
    });
    
    this.setState({
      coupons: coupons
    })
  };
  
  toggleFilters = (e) => {
    e.preventDefault;
    
    document.getElementById("couponFilters").classList.toggle("collapse");
    
    document.getElementById("coupons").classList.toggle("row-cols-xl-3");
    
    if (document.getElementById("showFiltersButton").textContent == "Show Filters") {
      document.getElementById("showFiltersButton").textContent = "Hide Filters";
    } else {
      document.getElementById("showFiltersButton").textContent = "Show Filters";
    }
    
  };

  // Modal Functions
  handleToggle = () => {
    if (this.state.show) {
      this.handleHide()
    } else {
      this.handleShow()
    }
  };
  handleShow = () => {
    let html = document.getElementsByTagName("html")[0];
    html.style.overflow = "hidden";
    this.setState({ show: true });
  };

  handleHide = () => {
    let html = document.getElementsByTagName("html")[0];
    html.style.overflow = "";
    this.setState({ show: false });
  };
  
  fetchCoupons = () => {
    let couponsURL = "/api/loyalty-lane-proxy?url=Coupon";
    
    if (this.state.user) couponsURL = "/api/loyalty-lane-proxy?url=availableCoupon&accountid=" + this.state.user.AccountId;
    
    fetch(couponsURL)
    .then(
      res => res.json()
    )
    .then((data) => {
      if (data.result == "OK") {
        this.loadCoupons(data.data.coupons)
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: " + data.message
        }})
      }
    })
    .catch((error) => {
      console.error(error);
      this.setState({'alert': {
        type: "danger",
        message: "Error: Error Loading Data"
      }})
    });
  };
  
  fetchUserCoupons = () => {
    fetch('/api/loyalty-lane-proxy?url=ClippedCoupon&shopperToken=' + this.state.token)
    .then(
      res => res.json()
    )
    .then((data) => {
      if (data.result == "OK") {
        if (data.data.coupons.length){
          this.loadCoupons(data.data.coupons, true);
        }
      } else {
        if (data.message == "ShopperToken is invalid or has expired.") {
          this.logoutUser();
          
          this.setState({'alert': {
            type: "warning",
            message: 'Please click "Sign In" above to login to your account.'
          }})
        } else {
          this.setState({'alert': {
            type: "danger",
            message: "Error: " + data.message
          }})
        }
      }
    })
    .catch((error) => {
      console.error(error);
      this.setState({'alert': {
        type: "danger",
        message: "Error: Error Loading Data"
      }})
    });
  };
  
  loadCoupons = (coupons, saved = false) => {

    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const today = new Date();
    const oneDay=1000*60*60*24;
    
    coupons.forEach(function(coupon,index) {
      // Set all coupons initially to show
      coupons[index]["show"] = true;
      
      if (saved) {
        coupons[index]["state"] = "saved";
      }else {
        coupons[index]["state"] = "unsaved";
      }
      
      
      // Set Strings for Expiration Date and Days Left
      let expDate = new Date(coupon["ExpirationDate"]);
      let strExpDate = "Exp. " + months[expDate.getMonth()] + " " + expDate.getDate();
       let daysLeft = Math.ceil((expDate.getTime()-today.getTime())/(oneDay)) - 1;
       let strDaysLeft = "";
       if (daysLeft == 0) {
         strDaysLeft = "Last Day!";
       } else if (daysLeft == 1) {
         strDaysLeft = "One day left!";
       } else if (daysLeft <= 5) {
         strDaysLeft = daysLeft + " days left!";
       }
      coupons[index]["ExpirationDateString"] = strExpDate;
      coupons[index]["DaysLeftString"] = strDaysLeft;
    });
    
    let allCoupons = coupons.concat(this.state.coupons);
    
    this.setState({
      coupons: allCoupons,
    })
    
    this.setupCategories();
  };
  
  setupCategories = () => {
    let coupons = this.state.coupons;
    
    let categories = new Array();
    let brands = new Array();
    
    coupons.forEach(function(coupon,index) {
      //Create array of Categories with counts
      let currentCategory = categories.findIndex(category => category.name == coupon["CategoryName"]);
      if (currentCategory == -1) {
        categories.push({"name": coupon["CategoryName"], count: 1, selected: false});
      } else {
        categories[currentCategory].count = categories[currentCategory].count + 1;
      }
      
      // Create array of Brands with counts
      let currentBrand = brands.findIndex(brand => brand.name == coupon["Brand"]);
      if (currentBrand == -1) {
        brands.push({"name": coupon["Brand"], count: 1, selected: false});
      } else {
        brands[currentBrand].count = brands[currentBrand].count + 1;
      }
    });
    
    // Sort categories by number of coupons
    categories.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
    
    // Sort brands by number of coupons
    //brands.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
    
    // Sort categories alphabetically
    //categories.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    
    // Sort brand alphabetically
    brands.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    
    this.setState({
      categories: categories,
      brands: brands
    })
  }
  
  logoutUser = () => {
    handleLogout();
    this.setState({
      user: null,
      coupons: []
    })
    this.fetchCoupons();
  }
  
  handleUserButton = () => {
    if (this.state.user) {
      this.logoutUser();
    }
    else {
      this.handleShow();
    }
  };
  
  saveCoupon = (e) => {
    if (this.state.user) {
      let coupon_id = e.target.getAttribute('data-coupon-id');
      let coupons = this.state.coupons;
      let couponIndex = coupons.findIndex(e => (e.id == coupon_id));
      coupons[couponIndex]["state"] = "saving";
      this.setState({coupons: coupons});
      
      fetch('/api/loyalty-lane-proxy?url=ClippedCoupon&shopperToken=' + this.state.token, {
        method: 'POST',
        body: JSON.stringify({
          'couponidlist': [Number(coupon_id)]
        }),
      })
      .then(
        res => res.json()
      )
      .then((data) => {
        if (data.result == "OK") {
          setTimeout(() => {
            coupons[couponIndex]["state"] = "saved";
            this.setState({coupons: coupons});
          }, 500);
        } else {
          coupons[couponIndex]["state"] = "unsaved";
          this.setState({coupons: coupons});
          this.setState({'alert': {
            type: "danger",
            message: "Error: " + data.message
          }})
        }
      })
      .catch((error) => {
        console.error(error);
        coupons[couponIndex]["state"] = "unsaved";
        this.setState({coupons: coupons});
        this.setState({'alert': {
          type: "danger",
          message: "Error: Error Loading Data"
        }})
      });
              
    } else {
      this.handleShow();
    }
  };
  
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidMount(){
    if (isBrowser() && !isLoggedIn()) {
      let hash = window.location.hash.replace('#', '');
      if (hash === "signin") {
        this.handleShow();
      } else if (hash === "signup") {
        this.handleShow();
        this.setState({activeModalTab: 'signup'});
      }
    }
    
    this.fetchCoupons();
    if (this.state.user) this.fetchUserCoupons();
  }

  render() {
    let alert;
    if (this.state.alert) {
      alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
    }
  
    let loadingCoupons;
    if (this.state.coupons.length < 1) {
      loadingCoupons = <CouponsLoader><img src={Loader} alt="loading" /><h3>Loading Coupons</h3></CouponsLoader>
    }
    
    let missingMyCoupons;
    if (this.state.coupons.filter(e => (e.state == "saved")).length < 1) {
      if (this.state.user) {
        missingMyCoupons = <h4>No Saved Coupons</h4>
      } else {
        missingMyCoupons = (
          <>
            <h4 style={{clear: 'both'}}>Sign in or sign up for an account to save coupons</h4>
            <br />
            <p>
              <CTAButton size="small" variant="solid-white" onClick={this.handleUserButton}>Sign In</CTAButton>
            </p>
          </>
        )
      }
    }
    
    const couponSort = (a,b) => {
      if (this.state.couponSort == "brand") {
        return (a.Brand.toLowerCase() > b.Brand.toLowerCase()) ? 1 : -1
      } else if (this.state.couponSort == "value") {
        return (a.Value < b.Value) ? 1 : -1
      } else if (this.state.couponSort == "expiration") {
        return (a.ExpirationDate > b.ExpirationDate) ? 1 : -1
      } else {
        if ((a.SourceName == b.SourceName) || ((a.SourceName != "In-Store") && (b.SourceName != "In-Store"))) {
          return (a.id > b.id) ? 1 : -1;
        } else if (a.SourceName == "In-Store") {
          return -1
        } else if (b.SourceName == "In-Store") {
          return 1
        } else {
          return (a.id > b.id) ? 1 : -1;
        }
      }
    }
    
    const coupons = this.state.coupons
      .filter(e => (e.show == true))
      .sort(couponSort)
      .map((coupon, index) => (
        <Coupon key={index} coupon={coupon} buttonFunction={this.saveCoupon} />
    ))
    
    const myCoupons = this.state.coupons
    .filter(e => (e.state == "saved"))
    .sort(couponSort)
    .map((coupon, index) => (
      <Coupon key={index} coupon={coupon}  buttonFunction={this.saveButton} />
    ))
    
    const categories = this.state.categories.map((category, index) => (
      <Checkbox
        name={category.name}
        label={category.name + " (" + category.count + ")"}
        isSelected={category.selected}
        onCheckboxChange={this.categorySelection}
        key={category.name}
      />
    ))
    
    const brands = this.state.brands.map((brand, index) => (
      <Checkbox
        name={brand.name}
        label={brand.name + " (" + brand.count + ")"}
        isSelected={brand.selected}
        onCheckboxChange={this.brandSelection}
        key={brand.name}
      />
    ))
    
    let userButtons;
    if (this.state.user) {      
      userButtons = (
        <div id="userButtons">
          <CTAButton size="small" variant="solid-white" href="/user">My Account</CTAButton>
          <CTAButton size="small" variant="solid-white" onClick={this.handleUserButton}>Sign Out</CTAButton>
        </div>
      )  
    } else {
      userButtons = 
        <div id="userButtons">
          <CTAButton size="small" variant="solid-white" onClick={this.handleUserButton}>Sign In</CTAButton>
        </div>
    }
        
    return (
      <>
      <ExternalWrapper>
        <CouponsHeader>
          {userButtons}
          
          <PageTitle>Digital Coupons</PageTitle>
          {alert}
        </CouponsHeader>
        <Container id="coupons-container">
          <Tab.Container id="coupons-tabs" defaultActiveKey={this.state.activeCouponsTab} transition={false} mountOnEnter={true}>
            <CouponsNav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="all">All Coupons</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="clipped">My Coupons</Nav.Link>
              </Nav.Item>
            </CouponsNav>
            <Tab.Content>
              <Tab.Pane eventKey="all">
                <CouponButtons><CTAButton id="showFiltersButton" size="small" variant="solid-white" onClick={this.toggleFilters}>Show Filters</CTAButton></CouponButtons>
                <Row>
                  {loadingCoupons}
                  <Col xs={12} lg={3} id="couponFilters" className="collapse">
                    <Row>
                      <CouponsFilter xs={12}>
                        <h4>Sort By</h4>
                        <div className="options">
                          <Form.Control 
                            name="couponSort"
                            as="select"
                            value={this.state.couponSort}
                            onChange={this.handleInputChange}
                          >
                            <option value="id">Default</option>
                            <option value="brand">Brand</option>
                            <option value="expiration">Expiration Date</option>
                            <option value="value">Value</option>
                          </Form.Control>
                        </div>
                      </CouponsFilter>
                      <CouponsFilter xs={6} lg={12}>
                        <h4>Categories</h4>
                        <div className="options">
                          {categories}
                        </div>
                        <a href="#" name={"ALL"} onClick={this.categorySelection}>Show All</a>
                      </CouponsFilter>
                      <CouponsFilter xs={6} lg={12}>
                        <h4>Brands</h4>
                        <div className="options">
                          {brands}
                        </div>
                        <a href="#" name={"ALL"} onClick={this.brandSelection}>Show All</a>
                      </CouponsFilter>
                    </Row>
                  </Col>
                  <Col>
                    <Row id="coupons" className="row-cols-1 row-cols-md-2 row-cols-xl-3">
                      {coupons}
                    </Row>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="clipped">
                <div>
                  {missingMyCoupons}
                  <Row id="coupons" className="row-cols-1 row-cols-md-2 row-cols-xl-3">
                    {myCoupons}
                  </Row>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
        <ScrollToTop />
      </ExternalWrapper>
      <Modal
        show={this.state.show}
        onHide={this.handleToggle}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton/>
        <LoginModalBody>						
          <Tab.Container id="user-form-tabs" defaultActiveKey={this.state.activeModalTab}>
            <LoginModalNav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="signin">Sign In</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="signup">Create an Account</Nav.Link>
              </Nav.Item>
            </LoginModalNav>
            <Tab.Content>
              <Tab.Pane eventKey="signin">
                <LoginForm/>
              </Tab.Pane>
              <Tab.Pane eventKey="signup">
                <SignupForm/>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </LoginModalBody>
      </Modal>
      </>
    )
  }
}
